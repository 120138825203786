<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { getParser, isAndroidDevice } from '@/utils/bowser';
import { appStoreId, googlePlayId } from '@/constants/default-meta-data';

import { useCookies } from '@vueuse/integrations/useCookies';
import StarIcon from './icons/star.svg';
import CloseIcon from './icons/close.svg';
import CdekIcon from './icons/cdek.svg';

const needToShow = ref(false);
const appLink = ref('');
const cookies = useCookies();

const closeCookieName = 'app-banner-closed';

// Параметр days - не магические числа. Я просто повторяю старую логику с хранением
const closeBanner = (days: number) => {
  cookies.set(closeCookieName, true, {
    path: '/',
    expires: new Date(Number(new Date()) + days * 1000 * 60 * 60 * 24),
  });

  needToShow.value = false;
};

onMounted(() => {
  if (cookies.get(closeCookieName)) {
    return;
  }

  const parser = getParser();
  const isIos = parser.getOS().name === 'iOS';

  // В сафарях есть свой банер. Туда не надо
  if (isIos && parser.getBrowser().name === 'Safari') {
    return;
  }

  if (isIos) {
    needToShow.value = true;
    appLink.value = `https://itunes.apple.com/ru/app/id${appStoreId}?mt=8"`;
    return;
  }

  if (isAndroidDevice()) {
    needToShow.value = true;
    appLink.value = `https://play.google.com/store/apps/details?id=${googlePlayId}`;
  }
});
</script>

<template>
  <div v-if="needToShow" class="smart-app-banner">
    <button class="smart-app-banner__button" @click="closeBanner(15)">
      <close-icon />
    </button>

    <div class="smart-app-banner__icon">
      <cdek-icon />
    </div>

    <div>
      <p class="smart-app-banner__title">{{ $t('smartBanner.title') }}</p>

      <div class="smart-app-banner__bottom">
        <star-icon v-for="i in 5" :key="i" />

        <span class="smart-app-banner__subtitle"> {{ $t('smartBanner.subtitle') }} </span>
      </div>
    </div>

    <a class="smart-app-banner__link" :href="appLink" @click="closeBanner(90)">
      {{ $t('open') }}
    </a>
  </div>
</template>

<style scoped lang="scss">
.smart-app-banner {
  background: $Surface_Neutral;
  display: flex;
  gap: 8px;
  padding: 12px 8px;
  align-items: center;

  &__icon {
    padding: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #000;
    margin-bottom: 4px;
  }

  &__subtitle {
    color: $Bottom_60;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    margin-left: 4px;
  }

  &__link {
    text-decoration: none;
    text-transform: uppercase;
    color: $Primary_Active;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.2px;
    margin-left: auto;
  }

  &__bottom {
    display: flex;
    align-items: center;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
